* {
  font-family: "Open Sans", sans-serif;
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

body {
  background: #f6f6f6;
}
