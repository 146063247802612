.userlist_container {
    padding: 20px 15px;
    border-radius: 10px;
    width: 95%;
    margin: 2rem auto;
  }
  .container {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: center;
    background-color: #fff;
    padding: 20px;
  }
  .tile {
    width: 200px;
    height: 150px;
    background-color: #f2f2f2;
    border-radius: 10px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    color: #333;
    text-align: center;
    transition: transform 0.2s ease-in-out;
  }
  .tile:hover {
    transform: scale(1.1);
  }
  .icon {
    font-size: 50px;
    margin-right: 10px;
  }
  .tile:nth-child(1) {
    background-color: #4CAF50;
    color: #fff;
  }
  .tile:nth-child(2) {
    background-color: #2196F3;
    color: #fff;
  }
  .tile:nth-child(3) {
    background-color: #f44336;
    color: #fff;
  }
  .tile:nth-child(4) {
    background-color: #ff9800;
    color: #fff;
  }
  .tile:nth-child(5) {
    background-color: #9c27b0;
    color: #fff;
  }
  .tile:nth-child(6) {
    background-color: #673ab7;
    color: #fff;
  }
  .tile:nth-child(7) {
    background-color: #3f51b5;
    color: #fff;
  }
  .tile:nth-child(8) {
    background-color: #e91e63;
    color: #fff;
  }