
.container {
  max-width: 600px;
  margin: 50px auto;
  padding: 20px;
  background-color: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.form {
display: flex;
flex-direction: column;
}

.h1 {
text-align: center;
margin-bottom: 20px;
}

.label {
margin: 10px 0 5px;
font-size: 14px;
}

.input, .textarea {
padding: 10px;
border: 1px solid #ccc;
border-radius: 4px;
font-size: 12px;
width: 100%;
}

.textarea {
resize: none;
height: 100px;
}

.button {
margin-top: 20px;
padding: 10px;
border: none;
border-radius: 4px;
font-size: 12px;
cursor: pointer;
width: 48%;
background-color: #007BFF;
color: #fff;
}

.button[type="submit"] {
background-color: #007BFF;
color: #fff;
}

.button[type="button"] {
  background-color: #007BFF;
  color: #fff;
border: 1px solid #ccc;
}
.link {
background-color: #fff;
color: #333;
border: 1px solid #ccc;
}

.buttons_container {
display: flex;
justify-content: space-between;
}

@media (max-width: 600px) {
.container {
    margin: 20px;
}
}
.userlist_container {
padding: 20px 15px;
border-radius: 10px;
width: 95%;
margin: 2rem auto;
}

