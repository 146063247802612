.general {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.puff {
    background: #2996d98d;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9999;
    width: 100%;
    height: 100vh;
    transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
}

.pic_cont {
  width: 100%;
}

.content_container, .picture_container {
  width: 90%;
  margin: auto;
}

.content, .forms {
  max-width: 500px;
  margin: auto;
  backdrop-filter: blur(16px) saturate(180%);
  background-color: #ffffff;
  filter: drop-shadow(1px 1px 8px #b2d2fd1d);
  border-radius: 12px;
  border: 1px solid rgba(255, 255, 255, 0.125);
  padding: 30px;
}

.content > h3,  .forms > h3{
  color: #031d40;
  font-weight: 700;
  font-size: 20px;
  margin-bottom: 1rem;
  text-align: center;
}

.content > form, .forms > form {
  width: 100%;
}

.content > form > div , .forms > form > div {
  display: flex;
  flex-direction: column;
  gap: 9px;
}

.content > form > div label, .froms > form > div label {
  font-size: 13px;
  font-weight: 600;
  color: #031d40;
}

.content > form > div > input, textarea, .forms > form > div > input  {
  padding: 10px 13px;
  border: 1px solid #1e1e1e;
  outline: 0;
  margin-bottom: 25px;
  color: #070000;
  font-size: 13px;
  border-radius: 4px;
  font-weight: 500;
  resize: none;
}

.content > form > div > input:focus, textarea:focus, .forms > form > div > input:focus {
  border: 1px solid #2995d9;
  transition: 0.4s linear;
}

.content > form > button, .forms > form > button {
  display: block;
  width: 100%;
  background-color: #031d40;
  color: #fff;
  outline: 0;
  padding: 10px;
  cursor: pointer;
  border: 1px solid transparent;
  border-radius: 4px;
}

.content > form > button:hover {
  background: transparent;
  color: #2995d9;
  border-color: #2995d9;
}

.upload {
  background: #2995d9;
  width: 100%;
  margin-bottom: 25px;
  padding: 10px 13px;
  cursor: pointer;
  color: #fff;
  border-radius: 5px;
  text-align: center;
  border: 0 !important;
}

.check_inspect {
  display: flex;
  flex-direction: row !important;
}
.userlist_container {
  padding: 20px 15px;
  border-radius: 10px;
  width: 95%;
  margin: 2rem auto;
}