.h1 {
    margin: 0;
    padding: 10px;
    background-color: #333;
    color: #fff;
    text-align: center;
  }
  
  .container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: 20px;
  }
  
  /* Define the styles for the tiles */
  .tile {
    background-color: #fff;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    margin: 10px;
    padding: 20px;
    width: 300px;
    height: 200px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  
  .tile h2 {
    margin: 0;
    font-size: 20px;
    color: #333;
  }
  
  .tile p {
    margin: 0;
    font-size: 16px;
    color: #666;
  }
  
  .tile button {
    padding: 10px 20px;
    background-color: #333;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    align-self: flex-end;
  }
  
  /* Add some colors to the tiles */
  .tile:nth-child(1) {
    background-color: #FFC0CB;
  }
  
  .tile:nth-child(2) {
    background-color: #FFDAB9;
  }
  
  .tile:nth-child(3) {
    background-color: #FFFFCC;
  }
  
  .tile:nth-child(4) {
    background-color: #B0E0E6;
  }
  
  /* Media queries for mobile responsiveness */
  @media only screen and (max-width: 600px) {
    .container {
      flex-direction: column;
      align-items: center;
    }
  
    .tile {
      width: 100%;
      margin: 10px 0;
    }
  }
  .userlist_container {
    padding: 20px 15px;
    border-radius: 10px;
    width: 95%;
    margin: 2rem auto;
  }