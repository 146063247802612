.gen_sidebar {
  position: relative;
  display: flex;
  width: 100%;
  height: 100%;
  /* align-items: baseline; */
}

.sidebar {
  background: linear-gradient(195deg, rgb(66, 66, 74), rgb(25, 25, 25));
  width: 250px;
  max-width: 100%;
  height: 100vh;
  padding: 20px;
  flex-grow: 1;
  overflow-y: hidden;
  
  /* position: fixed; */
}

.back {
  width: 30px;
  max-width: 100%;
  position: absolute;
  z-index: 99;
  top: 20px;
  left: 265px;
  cursor: pointer;
  display: none;
}

.back > img {
  width: 100%;
}

.container {
  width: 100%;
  margin: 1rem auto 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100%;
}

.head {
  width: 100%;
}

.head > div,
.head > a {
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
  padding: 5px;
  width: 100%;
  margin: 5px 0;
  text-decoration: none;
}

.head > div > img,
.head > .link > img {
  width: 25px;
}

.head > div > p,
.head > .link > p {
  color: #fff;
  font-size: 14px;
}

.child {
  margin-top: 7rem;
  position: relative;
}

.link.active {
  background-image: linear-gradient(
    to right top,
    #163483,
    #1f3b87,
    #28428b,
    #304a8e,
    #385192
  );
  border-radius: 4px;
}

.app_container {
  width: 80%;
  overflow-y: scroll;
  /* margin: auto; */
  /* position: relative; */
  /* left: 50%;
  right: -50%;
  transform: translate(-50%, 0); */
  height: 100vh;
}

/* .app_container::-webkit-scrollbar {
  width: 0px;
} */

@media screen and (max-width: 768px) {
  .sidebar.showSidebar {
    left: 0;
  }
  .sidebar {
    position: absolute;
    top: 0;
    bottom: 0;
    height: 100vh;
    left: -18em;
    z-index: 5;
    transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
  }

  .app_container {
    width: 100%;
  }

  .back {
    display: block;
  }
}

.sidenav {
  height: 100%;
  width: 200px;
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  background-color: #111;
  overflow-x: hidden;
  padding-top: 20px;
}

.sidenav a {
  padding: 6px 8px 6px 16px;
  text-decoration: none;
  font-size: 25px;
  color: #818181;
  display: block;
}

.sidenav a:hover {
  color: #f1f1f1;
}

.main {
  margin-left: 160px; /* Same as the width of the sidenav */
  font-size: 28px; /* Increased text to enable scrolling */
  padding: 0px 10px;
}

@media screen and (max-width: 600px) {
  .sidenav {
    width: 0;
    position: fixed;
    z-index: 1;
    top: 0;
    left: 0;
    background-color: #111;
    overflow-x: hidden;
    transition: 0.5s;
    padding-top: 60px;
  }

  .menu_bar {
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 50px;
    background-color: #111;
    z-index: 2;
  }

  .menu_bar span {
    font-size: 30px;
    color: white;
    cursor: pointer;
    margin-left: 15px;
    margin-top: 10px;
  }

  .open {
    width: 250px;
  }

  .main {
    position: absolute;
    top: 0;
    left: 0;
    margin-left: 0;
    width: 100%;
  }

  .open .main {
    margin-left: 250px;
  }
}