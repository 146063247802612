.cont {
    position: relative;
}

.puff {
    background: #2996d98d;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9999;
    width: 100%;
    height: 100vh;
    transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
}

.signup_container {
    display: flex;
}

.background {
    background: url(../signIn/assets/loginShip.jpg);
    background-size: cover;
    height: 100vh;
    object-fit: cover;
    display: flex;
    align-items: center;
    width: 55%;
    position: relative;
}

.color::after {
    position: absolute;
    content: '';
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
    opacity: 0.3;
    background: #2995D9;
}

.txt_container {
    width: 80%;
    margin: auto;
    position: relative;
    z-index: 99;
}

.txt_container > img:nth-child(1) {
    width: 40px;
}

.txt_container > h1 {
    color: #fff;
    font-weight: 600;
}

.txt_container > h1 > span {
    color: #031D40;
}


.txt_container > p {
    color: #fff;
    font-size: 14px;
    margin-top: 2rem;
    width: 70%;
}

.form_container {
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.form_section {
    width: 80%;
    margin: auto;
}

.form_container > div > h1 {
    color: #1E1E1E;
    font-weight: 700;
    margin-bottom: 15px;
}

.form_container > div > p:nth-child(2) {
    font-size: 12px;
    font-weight: 600;
    color: #1E1E1E;
    margin-bottom: 2rem;
}

.form {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
}

.form > span {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    width: 100%;
    position: relative;
}

.form > span > label {
    font-size: 12px;
    font-weight: 600;
    margin-bottom: 9.4px;
}

.form > span > input {
    width: 100%;
    border: 1px solid #1E1E1E;
    padding: 12px 10px;
    border-radius: 4px;
    margin-bottom: 10px;
    color: #070000;
    font-size: 13px;
    font-weight: 500;
    outline: 0;
}

.form > span > input:focus {
    border: 1px solid #2995D9;
    transition: .4s linear;
}

.err {
    position: absolute;
    color: rgb(255, 80, 80);
    font-size: 11px;
    bottom: -4px;
}

.form > span > img {
    width: 15px;
    cursor: pointer;
    position: absolute;
    right: 20px;
    top: 40px;
}

div.forms > form > button {
    width: 100%;
    padding: 9px;
    color: #fff;
    font-size: 14px;
    font-weight: 500;
    background: #031D40;
    margin-top: 2rem;
    border: 1px solid transparent;
    border-radius: 4px;
    cursor: pointer;
    margin-bottom: 3rem;
}

div.forms > form > button:hover {
    background: transparent;
    border: 1px solid #08428C;
    transition: all 0.4s linear;
    color: #031D40;
}

.form_container > div > p:nth-child(4) {
    text-align: center;
    font-size: 13px;
    color: #1E1E1E;
    font-weight: 600;
}

.form_container > div > p:nth-child(4) > span > a {
    color: #031D40;
    text-decoration: none;
}

@media screen and (max-width: 768px) {
    .signup_container {
        flex-direction: column;
    }

    .background {
        display: none;
    }

    .background, .form_container {
        width: 100%;
        height: 100vh;
    }

    .form > span {
        width: 100%;
    }
}

@media screen and (max-width: 518px) {
    .form {
        flex-direction: column;
    }

    .form > span {
        width: 100%;
    }

    div.forms > form > div:nth-child(1) {
        margin-bottom: 0;
    }

    .txt_container > p {
        width: 85%;
    }
}