.card {
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    max-width: 80%;
    margin: auto;
  }
  
  .card-header {
    background-color: #f2f2f2;
    padding: 20px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }
  
  .card-header h2 {
    margin: 0;
  }
  
  .card-header span {
    font-size: 16px;
  }
  
  .card-body {
    padding: 20px;
  }
  
  .order-item {
    margin-bottom: 20px;
  }
  
  .order-item h3 {
    margin: 0;
    font-size: 20px;
  }
  
  .order-item p {
    margin: 0;
    font-size: 16px;
  }
  
  .order-details-btn-section {
    text-align: right;
    margin-top: 20px;
  }
  
  .order-details-btn {
    background-color: #f2f2f2;
    border: none;
    color: #007bff;
    font-size: 16px;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.2s ease;
    margin-right: 10px;
  }
  
  .order-details-btn:hover {
    background-color: #ddd;
  }
  
  .payment-btn {
    background-color: #007bff;
    border: none;
    color: #fff;
    font-size: 16px;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.2s ease;
  }
  
  .payment-btn:hover {
    background-color: #0062cc;
  }
  
  @media only screen and (max-width: 767px) {
    .card {
      max-width: 100%;
      border-radius: 0;
    }
  
    .card-header {
      padding: 10px;
    }
  
    .card-header h2 {
      font-size: 24px;
    }
  
    .card-header span {
      font-size: 14px;
    }
  
    .card-body {
      padding: 10px;
    }
  
    .order-item h3 {
      font-size: 18px;
    }
  
    .order-item p {
      font-size: 14px;
    }
  
    .order-details-btn-section {
      text-align: center;
      margin-top: 10px;
    }
  
    .order-details-btn,
    .payment-btn {
      font-size: 14px;
      padding: 8px 16px;
      margin-right: 5px;
      margin-bottom: 10px;
    }
  }

  .userlist-container {
    padding: 20px 15px;
    border-radius: 10px;
    width: 95%;
    margin: 2rem auto;
  }
  