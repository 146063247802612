.error {
  height: 100vh;
  display: flex;
  align-items: center;
  width: 90%;
  margin: auto;
}

.error_container > p:nth-child(1) {
  color: #031D40;
  font-weight: 600;
  font-size: 14px;
}

.error_container > h1 {
  color: #101828;
  font-size: 60px;
  margin: 1rem 0;
}

.error_container > p:nth-child(3) {
  color: #475467;
}

.error_link {
  display: flex;
  align-items: center;
  gap: 2rem;
  margin-top: 2rem;
}

.error_link > a:nth-child(1) {
  text-decoration: none;
  color: #344054;
  font-size: 13px;
  display: flex;
  align-items: center;
  gap: 1rem;
  border: 1px solid #D0D5DD;
  border-radius: 4px;
  padding: 15px 20px;
  transition: all .7s ease;
}

.error_link > a:nth-child(1):hover {
  border: 1px solid #031D40;
  color: #031D40;
}

.error_link > a:nth-child(2) {
  text-decoration: none;
  color: #fff;
  font-size: 13px;
  border: 1px solid transparent;
  background: #031D40;
  border-radius: 4px;
  padding: 15px 20px;
  transition: all .7s ease;
}

.error_link > a:nth-child(2):hover {
  background: transparent;
  color: #031D40;
  border: 1px solid #031D40;
}

.error_link > a:nth-child(1) > img {
  width: 13px;
}
