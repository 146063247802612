.container_content {
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 95%;
  margin: auto;
}

.container {
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
}

.h1 {
  font-size: 36px;
  margin-bottom: 20px;
}

.account_info {
  border: 1px solid #ccc;
  padding: 20px;
  margin-bottom: 20px;
}

.row {
  display: flex;
  margin-bottom: 10px;
}

.label {
  flex-basis: 200px;
  font-weight: bold;
  margin-right: 10px;
}

.value {
  flex-grow: 1;
}

.account_actions {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.account_actions .a {
  color: #333;
  text-decoration: none;
  font-weight: bold;
  padding: 10px 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin-bottom: 10px;
  flex-basis: 30%;
  text-align: center;
}

@media screen and (max-width: 600px) {
  .label {
    flex-basis: 100%;
    margin-right: 0;
    margin-bottom: 5px;
  }
  
  .value {
    flex-basis: 100%;
  }
  
  .account_actions .a {
    flex-basis: 45%;
  }
}
