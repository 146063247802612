
.container {
  max-width: 600px;
  margin: 50px auto;
  padding: 20px;
  background-color: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.form {
display: flex;
flex-direction: column;
}

.h1 {
text-align: center;
margin-bottom: 20px;
}

.label {
margin: 10px 0 5px;
font-size: 14px;
}

.input, .textarea {
padding: 10px;
border: 1px solid #ccc;
border-radius: 4px;
font-size: 12px;
width: 100%;
}

.textarea {
resize: none;
height: 100px;
}

.button {
margin-top: 20px;
padding: 10px;
border: none;
border-radius: 4px;
font-size: 12px;
cursor: pointer;
width: 48%;
}

.button[type="submit"] {
background-color: #007BFF;
color: #fff;
}

.button[type="button"] {
background-color: #fff;
color: #333;
border: 1px solid #ccc;
}
.link {
background-color: #fff;
color: #333;
border: 1px solid #ccc;
}

.buttons_container {
display: flex;
justify-content: space-between;
}

@media (max-width: 600px) {
.container {
    margin: 20px;
}
}
.userlist_container {
padding: 20px 15px;
border-radius: 10px;
width: 95%;
margin: 2rem auto;
}

table {
  margin: 0;
  padding: 0;
  font-family: Arial, sans-serif;
  font-size: 14px;
}

/* Style the table */
.user_table {
  width: 100%;
  border-collapse: collapse;
  margin: 20px 0;
}

/* Style the table header */
.user_table thead th {
  background-color: #f2f2f2;
  text-align: left;
  padding: 10px;
}

/* Style the table rows */
.user_table tbody tr {
  border-bottom: 1px solid #f2f2f2;
}

.user_table tbody tr:nth-child(even) {
  background-color: #f8f8f8;
}

/* Style the table cells */
.user_table tbody td {
  padding: 10px;
}

/* Style the buttons */
.edit_btn, .delete_btn {
  background-color: #4CAF50;
  border: none;
  color: white;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 14px;
  margin: 4px 2px;
  cursor: pointer;
  padding: 6px 12px;
  border-radius: 4px;
}

.delete_btn {
  background-color: #f44336;
}

/* Media query for mobile responsiveness */
@media screen and (max-width: 600px) {
  .user_table, .user_table thead, .user_table tbody, .user_table th, .user_table td, .user_table tr {
    display: block;
  }

  .user_table thead tr {
    position: absolute;
    top: -9999px;
    left: -9999px;
  }

  .user_table tbody tr {
    margin-bottom: 20px;
  }

  .user_table tbody td {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #f2f2f2;
    padding-left: 50%;
  }

  .user_table tbody td:before {
    content: attr(data-label);
    position: absolute;
    left: 10px;
    font-weight: bold;
  }
}


