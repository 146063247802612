.delete_modal_container {
    position: absolute;
    backdrop-filter: blur(4px) saturate(200%);
    background-color: hsla(0, 0%, 99%, 0.6);
    width: 100%;
    height: 100%;
    top: 0;
    right: 0;
    z-index: 999;
  }
  
  .delete_modal {
    background-color: #fff;
    filter: drop-shadow(1px 1px 8px #e9e9e942);
    border: 1px solid #e7e4e0;
    border-radius: 16px;
    left: 50%;
    max-width: 350px;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translate(-50%, -50%);
    padding: 20px;
    text-align: center;
  }
  
  .delete_icon {
    background: #f6e4e4;
    width: 45px;
    height: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50px;
    margin: auto;
  }
  
  p:nth-child(2) {
    margin: 1rem 0;
    font-weight: 500;
    color: #3c3125;
  }
  
  .btn_cont {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
    gap: 1rem;
  }
  
  .btn_cont > button:nth-child(1) {
    background: #fff4ea;
    border: 1px solid #ffe4cc;
    border-radius: 36px;
    padding: 10px 25px;
    color: #3d1b52;
    cursor: pointer;
  }
  
  .btn_cont > button:nth-child(2) {
    background: #ce0909;
    border-radius: 36px;
    cursor: pointer;
    padding: 10px 25px;
    color: #fff;
    border: 0;
    cursor: pointer;
  }
  