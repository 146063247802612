.grid_container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    grid-gap: 20px;
    font-size: 15px;
  }
  
  .card {
    background-color: #fff;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .card_header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    border-bottom: 1px solid #eee;
  }
  
  .card_header .h33 {
    margin: 0;
  }
  
  .card_actions {
    display: flex;
    gap: 10px;
  }
  
  .card_body {
    padding: 10px;
  }
  
  .card_body .pp {
    margin: 0;
  }
  .card_actions button {
    background-color: #4CAF50;
    color: #fff;
    border: none;
    border-radius: 5px;
    padding: 10px;
    cursor: pointer;
  }
  
  .card_actions button:hover {
    background-color: #3e8e41;
  }
  
  .pagination {
    display: flex;
    justify-content: center;
    margin-top: 20px;
    margin-bottom: 20px;
  }
  
  .page_number {
    font-size: 18px;
    font-weight: bold;
    margin: 0 10px;
  }
  
  .page_button {
    background-color: #ffffff;
    border: 1px solid #cccccc;
    color: #333333;
    padding: 8px 16px;
    margin: 0 5px;
    cursor: pointer;
  }
  
  .page-button:hover {
    background-color: #f2f2f2;
  }
  
  .page-button.active {
    background-color: #007bff;
    border: 1px solid #007bff;
    color: #ffffff;
  }
  /* Expand tile on hover */
  
  .userlist_container {
    padding: 20px 15px;
    border-radius: 10px;
    width: 95%;
    margin: 2rem auto;
  }
  table {
    margin: 0;
    padding: 0;
    font-family: Arial, sans-serif;
    font-size: 14px;
  }
  
  /* Style the table */
  .user_table {
    width: 100%;
    border-collapse: collapse;
    margin: 20px 0;
  }
  
  /* Style the table header */
  .user_table thead th {
    background-color: #f2f2f2;
    text-align: left;
    padding: 10px;
  }
  
  /* Style the table rows */
  .user_table tbody tr {
    border-bottom: 1px solid #f2f2f2;
  }
  
  .user_table tbody tr:nth-child(even) {
    background-color: #f8f8f8;
  }
  
  /* Style the table cells */
  .user_table tbody td {
    padding: 10px;
  }
  
  /* Style the buttons */
  .edit_btn, .delete_btn {
    background-color: #4CAF50;
    border: none;
    color: white;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 14px;
    margin: 4px 2px;
    cursor: pointer;
    padding: 6px 12px;
    border-radius: 4px;
  }
  
  .delete_btn {
    background-color: #f44336;
  }
  
  /* Media query for mobile responsiveness */
  @media screen and (max-width: 600px) {
    .user_table, .user_table thead, .user_table tbody, .user_table th, .user_table td, .user_table tr {
      display: block;
    }
  
    .user_table thead tr {
      position: absolute;
      top: -9999px;
      left: -9999px;
    }
  
    .user_table tbody tr {
      margin-bottom: 20px;
    }
  
    .user_table tbody td {
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid #f2f2f2;
      padding-left: 50%;
    }
  
    .user_table tbody td:before {
      content: attr(data-label);
      position: absolute;
      left: 10px;
      font-weight: bold;
    }
  }
  
  input[type="text"] {
    border-radius: 8px;
    border: 1px solid #ccc;
    padding: 10px;
    width: 200px;
    font-size: 14px;
    font-family: sans-serif;
    color: #333;
    background-color: #fff;
    outline: none;
  }
  
  input[type="text"]:focus {
    border-color: #000;
    background-color: #eee;
  }
  