.general {
  width: 90%;
  margin: 2rem auto 0 auto;
}

.general > h1,
.history > h1 {
  font-size: 25px;
  color: #08428c;
}

.container_content {
  display: flex;
 
  justify-content: space-between;
  margin: 1.5rem 0 0 0;
}

.user_details {
  background: white;
  width: 200px;
  padding: 30px 20px;
  border-radius: 20px;
  filter: drop-shadow(2px 2px 8px rgba(211, 211, 211, 0.248));
  flex:0 0 32%;
  
  
}

.user_details > p {
  font-size: 15px;
  
  margin-bottom: 15px;
}

.user_details > h4 {
  font-size: 14px;
  font-weight: 500;
  
  color: #08428c;
}

.history {
  margin: 4rem auto;
}

.order_overview {
  margin-top: 2rem;
  background: #fff;
  padding: 20px;
  border-radius: 15px;
}

.overview {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.overview > h4 {
  color: #08418c9a;
  font-weight: 500;
}

.overview > div {
  width: 40px;
  background: #c8c7f9;
  filter: drop-shadow(2px 2px 8px rgba(211, 211, 211, 0.248));
  border-radius: 50px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.overview > div > img {
  width: 55%;
}


.orders {
  margin-top: 1.5rem;
}

.order_heading {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0 15px 0;
}

.order_heading > p {
  color: #373636;
  font-weight: 700;
  width: 20%;
  text-align: center;
  font-size: 14px;
  text-transform: uppercase;
}

.empty_container {
  width: 90%;
  margin: 3rem auto 0 auto;
}

.empty_container > div {
  width: 200px;
  margin: auto;
}

.empty_container > div > img {
  width: 100%;
}

.empty_container > p {
  text-align: center;
  color: #031d40;
  margin: 2rem 0 0 0;
  font-weight: 500;
}

.order_list {
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-align: center;
  margin: 1rem 0;
}

.order_list > h5, .order_list > p:nth-child(2) {
  color: #031d40;
  width: 20%;
  font-size: 15px;
}

.status, .updates {
  width: 20%;
}

hr {
  opacity: 0.4;
}

.updates, .pending {
  color: #08428c;
  font-weight: 700;
}

@media(max-width:768px){
  .container_content {
      flex-direction: column;
  }
}

.userlist_container {
  padding: 20px 15px;
  border-radius: 10px;
  width: 95%;
  margin: 2rem auto;
}

.button {
  background-color: #4CAF50;
    border: none;
    color: white;
    padding: 10px 20px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 10px 2px;
    cursor: pointer;
    border-radius: 5px;
}

.container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 20px;
  font-family: 'Roboto', sans-serif;
  background-color: #f5f5f5;
  font-size: 14px;
}

.column {
  flex: 1;
  padding: 10px;
  min-width: 300px;
}

.card {
  background-color: #ffffff;
  border-radius: 10px;
  padding: 20px;
  margin-bottom: 20px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  position: relative;

}

.card::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 4px;
  border-radius: 10px 10px 0 0;
}

.card:nth-child(1)::before {
  background-color: #4285f4;
}

.card:nth-child(2)::before {
  background-color: #34a853;
}

.card:nth-child(3)::before {
  background-color: #fbbc05;
}

.card:nth-child(4)::before {
  background-color: #ea4335;
}

.h33 {
  margin-bottom: 10px;
  font-weight: 500;
  font-size: 20px;
}

.pp {
  font-weight: 300;
  overflow-wrap:break-word;
}

@media (max-width: 768px) {
  .column {
    min-width: 100%;
  }
}