.container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 20px;
}

/* Define the styles for the tiles */
.tile {
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  margin: 10px;
  padding: 20px;
  width: 300px;
  height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  
}


.tile h2 {
  margin: 0;
  font-size: 20px;
  color: #333;
}

.tile p {
  margin: 0;
  font-size: 16px;
  color: #666;
}

.tile button {
  padding: 10px 20px;
  background-color: #333;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  align-self: flex-end;
}

/* Add some colors to the tiles */
.tile:nth-child(1) {
  background-color: #FFC0CB;
}

.tile:nth-child(2) {
  background-color: #FFDAB9;
}

.tile:nth-child(3) {
  background-color: #FFFFCC;
}

.tile:nth-child(4) {
  background-color: #B0E0E6;
}

/* Media queries for mobile responsiveness */
@media only screen and (max-width: 600px) {
  .container {
    flex-direction: column;
    align-items: center;
  }

  .tile {
    width: 100%;
    margin: 10px 0;
  }
}
.userlist_container {
  padding: 20px 15px;
  border-radius: 10px;
  width: 95%;
  margin: 2rem auto;
}
  .pagination {
    display: flex;
    justify-content: center;
    margin-top: 20px;
    margin-bottom: 20px;
  }
  
  .page_number {
    font-size: 18px;
    font-weight: bold;
    margin: 0 10px;
  }
  
  .page_button {
    background-color: #ffffff;
    border: 1px solid #cccccc;
    color: #333333;
    padding: 8px 16px;
    margin: 0 5px;
    cursor: pointer;
  }
  
  .page-button:hover {
    background-color: #f2f2f2;
  }
  
  .page-button.active {
    background-color: #007bff;
    border: 1px solid #007bff;
    color: #ffffff;
  }
  /* Expand tile on hover */
.tile:hover {
  transform: scale(1.05);
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.3);
  cursor: pointer;
}


table {
  margin: 0;
  padding: 0;
  font-family: Arial, sans-serif;
  font-size: 14px;
}

/* Style the table */
.user_table {
  width: 100%;
  border-collapse: collapse;
  margin: 20px 0;
}

/* Style the table header */
.user_table thead th {
  background-color: #f2f2f2;
  text-align: left;
  padding: 10px;
}

/* Style the table rows */
.user_table tbody tr {
  border-bottom: 1px solid #f2f2f2;
}

.user_table tbody tr:nth-child(even) {
  background-color: #f8f8f8;
}

/* Style the table cells */
.user_table tbody td {
  padding: 10px;
}

/* Style the buttons */
.edit_btn, .delete_btn {
  background-color: #4CAF50;
  border: none;
  color: white;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 14px;
  margin: 4px 2px;
  cursor: pointer;
  padding: 6px 12px;
  border-radius: 4px;
}

.delete_btn {
  background-color: #f44336;
}

/* Media query for mobile responsiveness */
@media screen and (max-width: 600px) {
  .user_table, .user_table thead, .user_table tbody, .user_table th, .user_table td, .user_table tr {
    display: block;
  }

  .user_table thead tr {
    position: absolute;
    top: -9999px;
    left: -9999px;
  }

  .user_table tbody tr {
    margin-bottom: 20px;
  }

  .user_table tbody td {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #f2f2f2;
    padding-left: 50%;
  }

  .user_table tbody td:before {
    content: attr(data-label);
    position: absolute;
    left: 10px;
    font-weight: bold;
  }
}

input[type="text"] {
  border-radius: 8px;
  border: 1px solid #ccc;
  padding: 10px;
  width: 200px;
  font-size: 14px;
  font-family: sans-serif;
  color: #333;
  background-color: #fff;
  outline: none;
}

input[type="text"]:focus {
  border-color: #000;
  background-color: #eee;
}
